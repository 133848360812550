import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article25062021 = ({ post }) => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">FORTINET FortiMail: E-Mail Security </h1>
            <p className="bold">Invitation to the free S&T Mold Webinar</p>

            <p className="bold">
              Friday, July 09, 2021 | 11:00 a.m | Bucharest Time{" "}
            </p>
            <p>
              Email is a critical tool for everyday business communication and
              productivity. It’s also a popular attack vector among threat
              actors trying to steal credentials, obtain sensitive data or steal
              funds by gaining access to banking information.
            </p>
            <p>
              With best-in-class performance validated by independent testing
              firms, FortiMail delivers advanced multi-layered protection
              against the full spectrum of email-borne threats. Powered by
              FortiGuard Labs threat intelligence and integrated into the
              Fortinet Security Fabric, FortiMail helps your organization
              prevent, detect, and respond to email-based threats including
              spam, phishing, malware, zero-day threats, impersonation, and
              Business Email Compromise (BEC) attacks.
            </p>
            <p>
              Because email security is critically important for any
              organization to protect its business, employees, data and
              productivity, you need to know how your email security will
              perform.
            </p>
            <p className="bold">REGISTER NOW! </p>
            <p>
              <a href="https://forms.office.com/r/Zab1cY1n7H">
                REGISTER FORM LINK
              </a>
            </p>

            <h1 className="title">Webinar details: </h1>
            <p>09 July 2021 </p>
            <p>Time: 11:00 – 12:20| Chisinau, Moldova </p>
            <p>Speaker: Silviu Pufan, Senior Systems Engineer, Fortinet </p>

            <p>Language: Romanian</p>
            <p>
              Please register using this form or calling S&T Marketing
              Department +373 60947047
            </p>
            <p>
              <a href="https://forms.office.com/r/Zab1cY1n7H">
                REGISTER FORM LINK
              </a>
            </p>
            <p className="bold">We look forward to your participation! </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article25062021;
